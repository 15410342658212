<template>
  <div id="ModalCreateKey" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Create</h4>
          <button
            class="btn-close"
            type="button"
            aria-label="Close"
            @click="closeModal()"
          ></button>
        </div>
        <div id="ModalBodyCreateKey" class="modal-body">
          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="">Source Key:</label>
              <input
                type="text"
                v-model="translationData.key"
                class="form-control"
              />
            </div>
            <div class="col-md-12 mb-3">
              <label for="">Source Text:</label>
              <ckeditor
                v-model="translationData.source_text"
                :config="{ autoParagraph: false, height: 100 }"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeModal()">
            {{ $translate('common.close') }}
          </button>
          <button type="button" @click="handleSubmit()" class="btn btn-primary">
            {{ $translate('common.save_changes') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, onMounted, watch } from 'vue';
  import { Modal } from 'bootstrap';
  import { useTranslationStore } from '@/stores/translationStore';
  import {
    BlockContainer,
    ToastMessage,
    UnBlocker,
  } from '@/core/services/general.service';
  import { useRoute } from 'vue-router';
  import { $translate } from '@/core/services/translator.service';

  const route = useRoute();

  const translationStore = useTranslationStore();

  const emit = defineEmits(['refreshDataGrid']);

  const props = defineProps({
    moduleName: {
      type: String,
      required: true,
    },
  });

  const ModalCreateKeyForm = ref(null);
  const translationData = ref({
    key: '',
    source_text: '',
  });

  onMounted(() => {
    ModalCreateKeyForm.value = new Modal(
      document.getElementById('ModalCreateKey'),
      {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: false,
      }
    );
  });

  const title = ref('');
  const editMode = ref(false);

  const openModal = () => {
    ModalCreateKeyForm.value.show();
  };

  const closeModal = () => {
    ModalCreateKeyForm.value.hide();
  };

  const handleSubmit = async () => {
    const PostData = {
      data: [
        {
          originalkey: translationData.value.key,
          newvalue: translationData.value.source_text,
          vardatatype: 'string',
        },
      ],
      fileName: props.moduleName,
      language: route.params.selected_language,
    };


    let loader = BlockContainer('translationDxGrid');

    const response = await translationStore.updateTranslations(
      props.moduleName,
      PostData
    );

    if (response) {
      translationData.value.source_text = '';
      emit('refreshDataGrid');
      ToastMessage(
        'success',
        'Translation saved successfully!',
        'Successfully!'
      );
    } else ToastMessage('error', 'An error ocurred!', 'Error');

    UnBlocker(loader);
  };

  defineExpose({
    openModal,
    closeModal,
  });
</script>
