<template>
  <div id="ModalAgencyForm" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{ title }}</h4>
          <button
              class="btn-close"
              type="button"
              aria-label="Close"
              @click="closeModal"
          ></button>
        </div>
        <div id="ModalBodyAgencyForm" class="modal-body">
          <Form
              ref="veeForm"
              as="form"
              id="formSubModule"
              @submit="handleSubmit()"
              v-slot="{
              values,
              meta,
              errors,
              errorBag,
              isSubmitting,
              isValidating,
              submitCount,
            }"
          >
            <div
                style="width: 100%; zoom: 1.5; display:none;"
                class="badge badge-primary"
            >
              test
              <pre> values: {{ values }}</pre>
              <pre>errors: {{ errors }}</pre>
              <pre>agencyFormData: {{ agencyFormData }}</pre>
            </div>

            <div class="row g-3 mb-2">
              <div class="col-md-4">
                <label class="form-label" for="validationCustom02">
                  {{ $translate('agencies.agency_form.parent_agency') }}
                </label>
                <ValidatedSelect2
                    :drop-down-parent="'#ModalAgencyForm'"
                    v-model="agencyFormData.parent_agency_id"
                    name="parent_agency_id"
                    :options="agencies"
                    text-key="title"
                    placeholder="Select an agency"
                    @select="parentAgencyChangeEvent($event)"
                    rules=""
                />
              </div>

              <div class="col-md-4">
                <label class="form-label">
                  {{ $translate('agencies.agency_form.agency_title') }}
                </label>
                <Field
                    v-model="agencyFormData.title"
                    name="title"
                    type="text"
                    class="form-control"
                    placeholder="Enter an agency title"
                    rules="required"
                    :class="{ 'is-invalid': errors.title }"
                />
                <ErrorMessage as="div" class="invalid-feedback" name="title"/>
              </div>

              <div class="col-md-4">
                <label class="form-label" for="validationCustom02">
                  {{ $translate('agencies.agency_form.email') }}
                </label>
                <Field
                    v-model="agencyFormData.email"
                    name="email"
                    type="text"
                    class="form-control"
                    placeholder="Enter an email"
                    rules="required|email"
                    :class="{ 'is-invalid': errors.email }"
                />
                <ErrorMessage as="div" class="invalid-feedback" name="email"/>
              </div>
            </div>

            <div class="row g-3 mb-2">
              <div class="col-md-4">
                <label class="form-label">
                  {{ $translate('agencies.agency_form.country') }}
                </label>
                <ValidatedSelect2
                    :drop-down-parent="'#ModalAgencyForm'"
                    v-model="agencyFormData.country"
                    name="country"
                    :options="countries"
                    custom-value-key="iso3"
                    placeholder="Select country"
                    @select="countryChangeEvent"
                    rules="required"
                />
              </div>
              <div class="col-md-4">
                <label class="form-label" for="validationCustom02">
                  {{ $translate('agencies.agency_form.state') }}
                </label>
                <ValidatedSelect2
                    v-model="agencyFormData.state"
                    name="state"
                    :options="states"
                    rules=""
                    :placeholder="statePlaceHolder"
                    @select="stateChangeEvent"
                />
              </div>

              <div class="col-md-4">
                <label class="form-label" for="validationCustom02">
                  {{ $translate('agencies.agency_form.city') }}
                </label>
                <ValidatedSelect2
                    :drop-down-parent="'#ModalAgencyForm'"
                    v-model="agencyFormData.city"
                    name="city"
                    :options="cities"
                    rules=""
                    placeholder="Select City"
                    @select="cityChangeEvent"
                />
              </div>
            </div>

            <div class="row g-3 mb-2">
              <div class="col-md-12">
                <label class="form-label">
                  {{ $translate('agencies.agency_form.address') }}
                </label>
                <Field
                    v-model="agencyFormData.address"
                    name="address"
                    type="text"
                    class="form-control"
                    placeholder="Enter an address"
                    rules="required"
                    :class="{ 'is-invalid': errors.address }"
                />
                <ErrorMessage
                    as="div"
                    class="invalid-feedback"
                    name="address"
                />
              </div>
            </div>

            <div class="row g-3 mb-2">
              <div class="col-md-4">
                <label class="form-label">
                  {{ $translate('agencies.agency_form.country_code') }}
                </label>
                <ValidatedSelect2CountryCodes
                    v-model="agencyFormData.country_code"
                    name="country_code"
                    custom-value-key="phonecode"
                    :options="countries"
                    rules="required"
                    @select="countryCodeChangeEvent($event)"
                    drop-down-parent="#ModalAgencyForm"
                />
              </div>
              <div class="col-md-4">
                <label class="form-label">
                  {{ $translate('agencies.agency_form.phone') }}
                </label>
                <Field
                    v-model="agencyFormData.phone"
                    name="phone"
                    type="text"
                    class="form-control"
                    placeholder="Enter a phone"
                    rules="required"
                    :class="{ 'is-invalid': errors.phone }"
                />
                <ErrorMessage as="div" class="invalid-feedback" name="phone"/>
              </div>
              <div class="col-md-4">
                <label class="form-label" for="validationCustom02">
                  {{ $translate('agencies.agency_form.fax') }}
                </label>
                <Field
                    v-model="agencyFormData.fax"
                    name="fax"
                    type="text"
                    class="form-control"
                    placeholder="fax"
                    rules=""
                    :class="{ 'is-invalid': errors.fax }"
                />
                <ErrorMessage as="div" class="invalid-feedback" name="fax"/>
              </div>
            </div>

            <div class="row g-3 mb-2">
              <div class="col-md-4">
                <label class="form-label">
                  {{ $translate('agencies.agency_form.post_code') }}
                </label>
                <Field
                    v-model="agencyFormData.post_code"
                    name="name"
                    type="text"
                    class="form-control"
                    placeholder="Enter a post code"
                    rules=""
                    :class="{ 'is-invalid': errors.post_code }"
                />
                <ErrorMessage
                    as="div"
                    class="invalid-feedback"
                    name="post_code"
                />
              </div>
              <div class="col-md-4">
                <label class="form-label" for="validationCustom02">
                  {{ $translate('agencies.agency_form.web_site') }}
                </label>
                <Field
                    v-model="agencyFormData.web_site"
                    name="web_site"
                    type="text"
                    class="form-control"
                    placeholder="Enter a web site"
                    rules=""
                    :class="{ 'is-invalid': errors.web_site }"
                />
                <ErrorMessage
                    as="div"
                    class="invalid-feedback"
                    name="web_site"
                />
              </div>
              <div class="col-md-4">
                <label class="form-label" for="validationCustom02">
                  {{ $translate('agencies.agency_form.agency_code') }}
                </label>
                <Field
                    v-model="agencyFormData.agency_code"
                    name="agency_code"
                    type="text"
                    class="form-control"
                    placeholder="Enter an agency code"
                    rules="required"
                    :class="{ 'is-invalid': errors.agency_code }"
                />
                <ErrorMessage
                    as="div"
                    class="invalid-feedback"
                    name="agency_code"
                />
              </div>
            </div>

            <div class="row g-3 mb-2">
              <div class="col-md-6">
                <label for="">Languages</label>

                <ValidatedDxTagBox
                    v-model="agencyFormData.selected_languages"
                    :items="active_languages"
                    display-expr="LangName"
                    value-expr="LangCode"
                    name="selected_languages"
                    rules="required"
                />

              </div>


              <div class="col-md-6">
                <label for="">Currencies</label>

                <ValidatedDxTagBox
                    v-model="agencyFormData.currencies"
                    :items="currencies"
                    display-expr="code"
                    value-expr="code"
                    name="currencies"
                    rules="required"
                />

              </div>
            </div>
          </Form>
        </div>

        <div class="modal-footer">
          <button
              type="button"
              class="btn btn-secondary"
              @click="closeModal"
          >
            {{ $translate('common.close') }}
          </button>
          <button type="submit" form="formSubModule" class="btn btn-primary">
            {{ $translate('common.save_changes') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {Field, Form, ErrorMessage} from 'vee-validate';
import {onMounted, reactive, ref, watch} from 'vue';
import {
  BlockContainer,
  ToastMessage,
  UnBlocker,
} from '@/core/services/general.service';
import ValidatedSelect2 from '@/components/ValidatedSelect2.vue';
import {useAgencyStore} from '@/stores/agencyStore';
import ValidatedSelect2CountryCodes from '@/components/ValidatedSelect2CountryCodes.vue';
import {errorService} from '@/core/services/error.service';
import {$translate} from '@/core/services/translator.service';
import ValidatedDxTagBox from "@/components/ValidatedDxTagBox.vue";
import { Modal } from 'bootstrap';

const modalAgencyForm = ref(null);
const agencyStore = useAgencyStore();

const countries = ref([]);
const agencies = ref([]);
const active_languages = ref([]);
const currencies = ref([]);

onMounted(async () => {
  const [
    countriesResult,
    agenciesResult,
    activeLanguagesResult,
    currenciesResult,
  ] = await Promise.all([
    agencyStore.getCountries(),
    agencyStore.getAgencies(),
    agencyStore.getActiveLanguages(),
    agencyStore.getCurrencies(),
  ]);

  countries.value = countriesResult;
  agencies.value = agenciesResult;
  active_languages.value = activeLanguagesResult;
  currencies.value = currenciesResult;
});

const parentAgencyChangeEvent = ({id, text}) => {
  agencyFormData.parent_agency_id = id;
};

const states = ref([]);
const statePlaceHolder = ref('Select State');


const countryChangeEvent = async ({id, text}) => {
  // console.log("change event worked!")
  agencyFormData.country = id;
  statePlaceHolder.value = 'Loading...';
  states.value = await agencyStore.getStates(id);
  setTimeout(() => {
    statePlaceHolder.value = 'Select State';
  }, 250);
};

const cities = ref([]);

const stateChangeEvent = async ({id, text}) => {
  agencyFormData.state = id;
  cities.value = await agencyStore.getCities(id);
};

const cityChangeEvent = ({id, text}) => {
  agencyFormData.city = id;
};

const countryCodeChangeEvent = ({id, text}) => {
  agencyFormData.country_code = id;
};

const veeForm = ref();

const agencyFormData = reactive({
  id: null,
  parent_agency_id: null,
  title: null,
  email: null,
  country: '',
  state: '',
  city: '',
  address: null,
  country_code: null,
  phone: null,
  fax: null,
  post_code: null,
  web_site: null,
  agency_code: null,
  selected_languages: null,
  currencies: null,
});

const emit = defineEmits(['close-modal', 'refresh-datagrid']);

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
});

watch(() => agencyFormData.country, async (newVal, oldVal) => {
  const loader = BlockContainer('ModalBodyAgencyForm');
  statePlaceHolder.value = 'Loading...';
  states.value = await agencyStore.getStates(newVal);
  setTimeout(() => {
    statePlaceHolder.value = 'Select State';
  }, 250);
  UnBlocker(loader);
});

watch(() => agencyFormData.state, async (newVal, oldVal) => {
  const loader = BlockContainer('ModalBodyAgencyForm');
  cities.value = await agencyStore.getCities(newVal);
  UnBlocker(loader);
});

watch(()=> agencyFormData.languages, (newVal,oldVal)=>{
  // console.log("changed, new=> ",newVal + " old=> ", oldVal);
})



const handleSubmit = async () => {
  const loader = BlockContainer('ModalBodyAgencyForm');

  // console.log("here is the sending dagta", agencyFormData)

  await agencyStore
      .createOrUpdateAgency(agencyFormData)
      .then((response) => {
        ToastMessage('success', response.data.message, 'Başarılı!');

        clearData();

        closeModal()
        emit('refresh-datagrid');
      })
      .catch((error) => {
        errorService.apiClientError(error, true, true);
      })
      .finally(() => {
        UnBlocker(loader);
      });
};

const clearData = () => {
  Object.assign(agencyFormData, {
    id: null,
    parent_agency_id: null,
    title: null,
    email: null,
    country: '',
    state: '',
    city: '',
    address: null,
    country_code: null,
    phone: null,
    fax: null,
    post_code: null,
    web_site: null,
    agency_code: null,
    selected_languages: null,
    currencies: null,
  });
};

watch(
    () => agencyStore.getEditSubModuleData,
    (newData) => {
      if (newData) {
        // console.log("new data assigned", newData)
        Object.assign(agencyFormData, newData);
        agencyFormData.parent_agency_id = newData.parent_agency_id;
      } else {
        clearData();
      }
    },
    {immediate: true}
);


onMounted(() => {
  modalAgencyForm.value = new Modal(
      document.getElementById('ModalAgencyForm'),
      {
        backdrop: false,
        keyboard: false,
        focus: true,
        show: false,
      }
  );
});

const openModal = () => {
  clearData()
  modalAgencyForm.value.show();
};

const closeModal = () => {
  modalAgencyForm.value.hide();
};

defineExpose({
  openModal,
  closeModal
})

</script>

<style scoped>
/* Add your styles here */
</style>
