import {apiClient, apiClientFullURL} from "@/core/common/http-common";

export default class AgencyService {
    const
    base_url = apiClientFullURL;

    agenciesResource() {
        return this.base_url + "/Agencies/Agency";
    }

    async createAgency(agency_data) {
        return await apiClient.post(`/Agencies/Agency`, agency_data);
    }

    async updateAgency(agency_data) {
        return await apiClient.put(`/Agencies/Agency/${agency_data.id}`, agency_data);
    }

    async getAgencies() {
        return await apiClient.get(`/Agencies/GetAgencies`);
    }

    async getActiveLanguages() {
        return await apiClient.get(`/Agencies/GetActiveLanguages`);
    }

    async getCurrencies() {
        return await apiClient.get(`/Agencies/GetCurrencies`);
    }

    async saveAgencyParentRelationship(agency_data) {
        return await apiClient.post(`/Agencies/SaveAgencyRelationship`, agency_data);
    }

    async getAgencyDetails(agency_id) {
        return await apiClient.get(`/Agencies/Agency/${agency_id}`);
    }

    async getDiagramData() {
        return await apiClient.get(`/Agencies/GetDiagramData`);
    }

    async createAgencyMarkup(data) {
        return await apiClient.post(`/Agencies/Transactions/CreateOrUpdateAgencyMarkup`, data);
    }

    async updateAgencyMarkup(data) {
        return await apiClient.put(`/Agencies/Transactions/CreateOrUpdateAgencyMarkup`, data);
    }

    async deleteAgencyMarkup(markup_id) {
        return await apiClient.post(`/Agencies/Transactions/DeleteAgencyMarkup`, {markup_id: markup_id});
    }

    /**
     * Default Markups
     * */

    defaultMarkupsResource() {
        return this.base_url + "/Agencies/DefaultMarkups";
    }

}

export const agencyService = new AgencyService();