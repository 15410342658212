import {useAuthStore} from "@/stores/authStore";
import {ToastMessage} from "@/core/services/general.service";


export const authMiddleware = (to, from, next) => {
    const authStore = useAuthStore();

    authStore.checkTokenExpiry();

    const storedUser = JSON.parse(localStorage.getItem("user"));

    const layout = storedUser && storedUser.role === 'reservationist' ? 'Singapore' : 'Dubai';

    if (!to.query.layout) {
        console.log("we added the layout parameter");
        return next({
            ...to,
            query: { ...to.query, layout: layout }
        });
    }

    if (!authStore.user) {
        ToastMessage('error', 'Lütfen giriş yapın!', 'Hata');
        return next('/Login');
    }

    return next();
};
export const loginMiddleware = (to, from, next) => {
    const authStore = useAuthStore()
    if (authStore.user) {
        ToastMessage("error", "Logout first!", "Error!")
        return next('/')
    } else
        return next()
}
